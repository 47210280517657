<template>
    <div class="appform">
        <div class="content">
            <div style="display: flex;width: 100%;align-items: flex-start;">
                <div style="display: flex;flex: 1;">
                    <img src="../../assets/image/logo.png" style="width: 240px;height: auto; margin-right: 32px" />
                    <img src="../../assets/image/wao_logo.png" style="width: 160px;height: auto;object-fit: cover;" />
                </div>
                <p style="width: 300px;font-size: 12px;text-align: right;color: #666;line-height: 20px;">
                    Extracurricular Activity Excellence (EAE) stands as a global partner to the World Academic Olympics
                    (WAO), entrusted with the role of its operation.</p>
            </div>

            <div class="title1">World Academic Olympics Registration</div>
            <el-form :model="form" :rules="formRules" ref="form">
                <div class="row">
                    <div class="item">
                        <el-form-item label="Given Name (Native Language)" prop="givenName">
                            <div class="input">
                                <el-input v-model="form.givenName" placeholder="Please input"></el-input>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="Last Name (Native Language)" prop="lastName">
                            <div class="input">
                                <el-input v-model="form.lastName" placeholder="Please input"></el-input>
                            </div>
                        </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="item">
                        <el-form-item label="Given Name (EN)" prop="givenName_pinyin">
                            <div class="input">
                                <el-input v-model="form.givenName_pinyin" placeholder="Please input"></el-input>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="Last Name (EN)" prop="lastName_pinyin">
                            <div class="input">
                                <el-input v-model="form.lastName_pinyin" placeholder="Please input"></el-input>
                            </div>
                        </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="item">
                        <el-form-item label="Email" prop="email">
                            <div class="input">
                                <el-input v-model="form.email" placeholder="Please input"></el-input>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="High School Graduation" prop="graduation">
                            <div class="input">
                                <el-select v-model="form.graduation" style="width: 400px;" placeholder="Please select"
                                    :popper-append-to-body="false" popper-class="customSelect">
                                    <el-option v-for="(year, index) in years" :value="year" :key="index"
                                        :label="year"></el-option>
                                </el-select>
                            </div>
                        </el-form-item>
                    </div>
                </div>

                <div class="row">
                    <div class="item">
                        <el-form-item label="Country/Region" prop="country">
                            <div class="input">
                                <el-select v-model="form.country" style="width: 400px;" placeholder="Please select"
                                    @change="handleCountryChanged">
                                    <el-option v-for="(country, index) in countryList" :key="index"
                                        :label="country.country_en" :value="country.country_en"></el-option>
                                </el-select>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="Province/State" prop="province">
                            <div class="input">
                                <el-input v-model="form.province" placeholder="Please input"></el-input>
                            </div>
                        </el-form-item>
                    </div>
                </div>

                <div class="row" style="align-items: flex-start">
                    <div class="item">
                        <el-form-item label="Guardian's Email" prop="student_guardian_first_email">
                            <div>
                                <div class="input">
                                    <el-input v-model="form.student_guardian_first_email"
                                        placeholder="Please input"></el-input>
                                </div>
                            </div>
                            <p style="color: #666;font-size: 12px;line-height: 16px;margin-top: 10px;">The guardian's
                                email only serves as a backup for communication if necessary.</p>

                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="School Name(EN)" prop="account_id">
                            <div>
                                <div class="input" style="margin-top: 10px;">
                                    <el-select v-model="form.account_id" :multiple="false" filterable clearable="true"
                                        remote reserve-keyword placeholder="Please input keyword"
                                        :remote-method="remoteMethod" :loading="loading" loading-text="Loading"
                                        no-data-text="No Result" style="width: 400px;" @change="handleAccoutChanged"
                                        @blur="handleBlurChanged">
                                        <el-option v-for="(account, index) in accounts" :key="index"
                                            :label="account.account_nameEN ? account.account_nameEN : account.account_name"
                                            :value="account._id.$id">
                                        </el-option>
                                    </el-select>
                                </div>
                                <p style="color: #666;font-size: 12px;line-height: 16px;margin-top: 10px;">Please enter
                                    the school's official name with initial caps of each word.</p>
                            </div>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
            <div class="terms flex items-center justify-center" style="margin-top: 20px;">
                <div @click="isAgree = !isAgree" style="display: flex; align-items: center">
                    <img style="width: 16px;height: 16px;cursor: pointer;margin-right: 16px;" v-if="!isAgree"
                        :src="require('../../assets/image/unchecked.png')" />
                    <img style="width: 16px;height: 16px;cursor: pointer;margin-right: 16px;" v-else
                        :src="require('../../assets/image/checked.png')" />
                </div>
                <p style="line-height: 20px;"> I agree that EAE may use my provided information to set up a personal account and <br> to email me about notifications, reminders, and extracurricular opportunities.</p>
            </div>
            <div class="submit">
                <div class="submitBtn" @click="submit">Submit</div>
            </div>
        </div>


        <el-dialog
            title="Mailbox verification"
            :visible.sync="dialogVisible"
            width="30%">

            <span>{{student_email}}</span>

            <div style="margin-top: 20px;">
                <el-input v-model="verify_code" auto-complete="off" placeholder="Please enter the code"
                    type="text" style="width: 200px;">
                </el-input>
                <el-button slot="suffix" class="send_code" @click="sendCode">Send Code</el-button>

            </div>


            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="mailClick()">确 定</el-button>
            </span>
        </el-dialog>
    </div>

   
</template>
<script>
import {
    getCountries,
    searchAccounts,
    addStudentInDatabaseV3,
    createREPassOrder,
    createLoginOPT,
    loginByStudentV2
} from '../../api/eae'

import {
  setUserId,
  setUserName,
  setUserAccountCountry,
  setUserType,
  setAccountId,
} from "../../utils/store";

import '../../assets/common/font.css'
export default ({
    name: 'index',
    data() {
        return {
            isAgree: false,
            accounts: [],
            loading: false,
            years: [],
            form: {
                project_code: 'WAO',
                account_id: '',
                account_name: '',
                account_nameEN: '',
                province: '',
                country: '',

                lastName: '',
                givenName: '',
                lastName_pinyin: '',
                givenName_pinyin: '',
                graduation: '',
                email: '',

                student_guardian_first_email: ''
            },
            formRules: {
                givenName: [
                    { required: true, message: "Please input", trigger: "change" },
                ],
                lastName: [
                    { required: true, message: "Please input", trigger: "change" },
                ],
                account_nameEN: [
                    { required: true, message: "Please input", trigger: "change" },
                ],
                province: [
                    { required: true, message: "Please input", trigger: "change" },
                ],
                country: [
                    { required: true, message: "Please select", trigger: "change" },
                ],
                lastName_pinyin: [
                    { required: true, message: "Please input", trigger: "change" },
                ],
                givenName_pinyin: [
                    { required: true, message: "Please choose", trigger: "change" },
                ],
                graduation: [
                    { required: true, message: "Please choose", trigger: "change" },
                ],
                account_id: [
                    { required: true, message: "Please input", trigger: "change" },
                ],
                email: [{ required: true, message: "Please input", trigger: "change" }],
            },
            countryList: [],
            dialogVisible:false,
            verify_code:'',
            student_email:'',
            selectCountryEn: ''
        }
    },
    mounted() {
        let current_year = new Date().getFullYear()
        let start_year = current_year
        for (let i = 1; i < 5; i++) {
            let year = start_year + i
            this.years.push(year)
        }
        this.requestCountryList()
    },
    methods: {

        handleCountryChanged() {
            for (let i = 0; i < this.countryList.length; i++) {
                if (this.countryList[i].country_en == this.form.country) {
                    this.selectCountryEn = this.countryList[i].country_en
                    break
                }
            }
        },

        requestCountryList() {
            getCountries().then((res) => {
                this.countryList = res.data
            })
        },


        handleBlurChanged(e) {
            let that = this
            setTimeout(() => {
                if (!that.form.account_id || that.accounts.length <= 0) {
                    that.form.account_id = that.form.account_nameEN
                }
            }, 100)
        },

        handleAccoutChanged(e) {
            for (let i = 0; i < this.accounts.length; i++) {
                let account = this.accounts[i]
                if (account._id.$id == this.form.account_id) {
                    this.form.account_nameEN = account.account_nameEN ? account.account_nameEN : account.account_name
                    break
                }
            }
        },

        remoteMethod(keyword) {
            if (!keyword) {
                return
            }
            this.form.account_nameEN = keyword
            this.loading = true
            searchAccounts(keyword, this.selectCountryEn).then((res) => {
                this.loading = false
                this.accounts = res.data
            })
        },
        submit() {
            if (!this.isAgree) {
                this.$message.warning("You must agree to the statement.");
                return;
            }
            this.$refs.form.validate((val) => {
                if (val) {
                    if (this.form.account_id == this.form.account_nameEN) {
                        this.form.account_id = ''
                    }
                    addStudentInDatabaseV3(this.form).then((res) => {
                        let student = res.data

                        this.student_email = student.student_email
                        this.dialogVisible = true
                    });
                }
            });
        },
        mailClick(){

            if (this.verify_code == "") {
                this.$message.error("Please enter the code");
                return;
            }

            loginByStudentV2(
                this.student_email,
                this.verify_code
            ).then((res) => {


                let result = res.data;
                if (result.code == 0) {

                    this.dialogVisible = false

                    setUserId(result.data._id.$id);
                    let account_country = "China";
                    if (result.data.account_country) {
                    account_country = result.data.account_country;
                    }
                    setUserAccountCountry(account_country);
                    const user_name =
                    result.data.student_givenName_pinyin + ' ' + result.data.student_lastName_pinyin;
                    setUserName(user_name);
                    setUserType("student");
                    setAccountId(result.data.account_id);
                    this.$UserData.user_name = user_name;
                    this.$UserData.account_country = account_country;
                    
                    this.doCreatePassOrder(result.data._id.$id)
                    
                } else {
                    this.$message.error("Incorrect verification code");
                }
            });


        },

        sendCode(){
            if (this.student_email == "") {
                this.$message.error("Please enter your email");
                return;
            }
            createLoginOPT(this.student_email).then((res) => {
                console.log(res.data,'createLoginOPT')
                let result = res.data
                if(result.code === 0){
                this.$message.success('Sent Successfully');
                }else{
                this.$message.error('Verification code sent failed. Please try again.')
                }
            })
        },


        doCreatePassOrder(student_id) {

            //10月1日及之前，提交把名表创建24-25WAO_EBEntry这张pass的待支付
            //10月1日之后，提交报名表创建的是24-25WAO_Entry这张pass的待支付
            let pass_id = ''
            let current_time = new Date().getTime()
            if (current_time < 1727798400000) {
                pass_id = '669e1e63a4640b623d8b456e'
            } else {
                pass_id = '6699cf25a4640bba118b457d'
            }
            createREPassOrder(student_id, pass_id,'EAE-Application').then((res) => {
                let result = res.data
                if (result.code == 0) {
                    // this.$router.replace({
                    //     path: '/form/success',
                    //     query: {
                    //         id: result.data._id.$id,
                    //         project: 'WAO'
                    //     }
                    // })

                    this.$router.replace({
                        path: '/home/myPasses',
                        query: {
                            id: result.data._id.$id,
                            project: 'WAO'
                        }
                    })
                    
                } else {
                    this.$message.error(result.msg);
                }
            })
        }
    }
})
</script>
<style scoped lang="scss">
::-webkit-scrollbar {
    display: none;
}

.appform {
    font-family: GravityNormal;
    width: 100vw;
    height: 100vh;
    background-color: #043280;

    .content {
        overflow-y: auto;
        width: 1000px;
        height: 100vh;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 30px 40px 20px;
        background: #fff;
        position: relative;

        .title1 {
            margin-top: 30px;
            font-family: GravityBold;
            color: #043280;
            font-size: 22px;
            margin-bottom: 50px;
            align-items: center;
            width: 100%;
            font-weight: bold;
            text-align: center;
        }

        .row {
            display: flex;
            justify-content: space-between;

            .item {
                width: 420px;

                .label {
                    font-size: 16px;
                    font-family: GravityNormal;
                    font-weight: normal;
                    color: #666666;
                }

                .input {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    font-size: 18px;

                    font-family: GravityNormal;
                    font-weight: normal;
                    color: #333333;

                    img {
                        margin-right: 24px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .submit {
        .submitBtn {
            font-family: GravityBold;
            width: 280px;
            height: 50px;
            background: #043280;
            border-radius: 25px;
            text-align: center;
            line-height: 50px;
            color: #fff;
            font-size: 20px;
            margin: 0 auto;
            margin-top: 20px;
            cursor: pointer;
        }

        margin-bottom: 50px;
    }
}
</style>
<style lang="scss">
.appform {
    .el-input__inner {
        border-radius: 6px;
        border-color: #043280;
        font-family: GravityNormal;
        // border: 2px solid #717171;
        // font-size: 18px;
    }

    .el-form-item__label {
        font-family: GravityNormal;
    }
}

.successConfirm {
    width: 470px;

    .el-message-box__status.el-icon-success {
        top: 11% !important;
    }
}
.send_code{
    margin-left: 20px;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
}
</style>